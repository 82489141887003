.icon.open::before {
    content: "\25B2"; }
  
  .icon.closed::before {
    content: "\25BC"; }
  
  .header-content {
    display: flex;
    /* Display items in a row */
    align-items: center;
    /* Vertically align items */
    justify-content: space-between;
    cursor: pointer; }
  
  .accordion-content-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer; }
  
  .arrow.open::before {
    content: "\2192"; }
  
  .arrow.closed::before {
    content: "\25BC"; }
  
  .accordion-content {
    background-color: #EEEEEE; }
  
  .nested-item {
    background-color: #F6F9FF;
    border-radius: 6px;
    text-align: left;
    padding: 30px; }
  
  .detailed-item-col {
    margin-top: 0;
    /* You can adjust this value as needed */
    margin-bottom: 0;
    /* You can adjust this value as needed */ }
  
  .card-details {
    width: 200px;
    height: 140px;
    border-radius: 16px;
    border: 1px solid #E7E7E7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px; }
  
  .card-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px; }
  
  .icon-card {
    font-size: 24px;
    margin-bottom: 87px;
    margin-right: 24px;
    /* Adjust as needed */ }
  
  .row {
    display: flex;
    justify-content: space-between; }
  
  p {
    margin: 0;
    font-size: 14px;
    color: #000; }
  
  .support-modal {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
   }
  
  .help-text {
    font-size: 10px; }
  
  .item3 {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    font-size: 12px; }
  
  .item2 {
    font-size: 10px;
    margin-right: 33px; }
  
  .item1 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 200; }
  
  .arrow-icon {
    margin-top: -8px;
    margin-left: 20px; }
  