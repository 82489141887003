.welcome-message {
  margin-bottom: 2.5rem;
}

//.activities h4{
//  //font-size: $main-heading;
//  font-size: 24px;
//  text-align: center;
//
//}
//
//.font-centered{
//  //text-align: center;
//  //font-weight: 700;
//  //margin: 10px;
//}