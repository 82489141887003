/*******************/
/*Chat widget*/
/*******************/
.chat-box {
  overflow: auto;
}
.chat-list {
  margin: 0px;
  padding: 0px;
 overflow: auto;
  .chat-item {
    list-style: none;
    margin-top: 30px;
    .chat-img {
      display: inline-block;
      width: 45px;
      vertical-align: top;
      img {
        width: 45px;
        border-radius: 100%;
      }
    }
    .chat-content {
      width: calc(100% - 140px);
      display: inline-block;          
      .box {
		border-radius: 4px;
        display: inline-block;
        padding: 10px;
        margin-bottom: 3px;
        color: $gray-800;
        background: $light-info;
      }
    }
    .chat-time {
      display: block;
      font-size: 10px;
      color: $gray-700;
      margin: 5px 0 15px 65px;
    }
    &.odd {
      .chat-content {
        text-align: right;
        width: calc(100% - 0px);
      }
      .chat-time {
        text-align: right;
      }
      .box {
        clear: both;
        color: $gray-800;
        background: $light-inverse;
      }
    }
    &.odd + .odd {
      margin-top: 0px;
    }
    &.reverse {
      text-align: right;
      .chat-time {
        text-align: left;
      }
      .chat-content {
        padding-left: 0px;
        padding-right: 15px;
      }
    }
  }
}
