.section-title {
    border-bottom: 1px solid #888;
    margin-top: 30px;
    margin-bottom: 26px;
    padding-bottom: 15px;
    font-weight: 600;
}

.last-section {
    padding-bottom: 70px;
    border-bottom: 1px solid #888;
}

@media only screen and (min-width: 768px) {
    .remove-contact {
        position: absolute;
        top: 30%;
    }
}
