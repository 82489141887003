.faan-payment-container {
  background-color: white;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .faan-payment-content {
    background-color: #FAFAFA;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 20px;
    width: 80%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .invoice-search {
    display: flex;
    width: 422px;
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;
  }
  .input-invoice {
    display: flex;
    height: 40px;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
  }
  .enter-invoice {
    color: "#ffffff";
    font-size: 16;
    font-weight: "500";
    word-wrap: "break-word";
    z-index: 40;
  }
}


.invoiceModal{
    width: 837px;
   height: 490px;
   border-radius: 20px;
   background: #F5F5F5;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
