
h1,h2,h3,h4,h5,h6,p {
  /* color: red !important; */
}



.centerFields{
    margin:  auto;
    width: auto;
}
/* .moveRight {
    float: left;
} */
/* .auditSearchButton{
    border-color: transparent !important;
    width: 50%;
    background-color: #40d999 !important;
} */
.search-button{
  margin-top: -11px;
  margin-bottom: 20px;
}

input[type="checkbox"] {
    visibility: hidden;
  }
  .checkboxOverride {
    position: relative;
    width: 25px;
  }
  .checkboxOverride input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
  .checkboxOverride label {
    background: none repeat scroll 0 0 #EEEEEE;
    border: 1px solid #DDDDDD;
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
  }
  .checkboxOverride label:after {
    border-style: none none solid solid;
    content: "";
    height: 5px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 7px;
    -ms-transform:rotate(-45deg); /* IE 9 */
    -webkit-transform:rotate(-45deg); /* Safari and Chrome */
    transform:rotate(-45deg);
    width: 10px;
  }

  .audit-date {
    display: block
  }

  .audit-day {
    font-size: 20px;
  }

