#cardLog {
    border-radius: 1rem;
    /*height: 00px;*/
    width: 400px;
}

.headerText {
    color: white;
}

.baseText {
    color: white;
}

.loginBtn {
    color: white !important;
    /*margin-bottom: 1rem;*/

}

.loginBtn:hover {
    color: #000;
}

.managerLogin {
    background-color: white !important;
    border-color: black !important;
}

.managerLogin:hover {
    color: black !important;
}

.vh100 {
   height: 100vh;
    }

input {
    font-size: 15px;
    padding: 10px 10px 10px 5px;
    width: 300px;
    border: none;
    line-height: 0.9;
    color: black;
    border-bottom: 1px solid #757575;
}

input:focus {
    outline: none;
    cursor: text;
    line-height: 1.5;
}

input:focus {
    outline: none;
    cursor: text;
    line-height: 1.5;
}

::placeholder {
    opacity: 0.5;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.5;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    opacity: 0.5;
}