.btnOne {
  position: relative;
  display: inline-block;
  margin: 1em;
  padding: 1em 2em;
  border: 0;
  border-radius: 30px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
.smallSize {
  font-size: 0.8rem;
}
.btnOne:hover {
  box-shadow: inset 0 -3px 0 rgba(255, 255, 255, 0.507);
}
.btnOne:active {
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
}
.btnOne:focus {
  outline: none;
}

.btnOne--action {
  background-color: transparent;
  color: white;
}

.btnOne-group {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  padding: 0;
  margin: 0;
}
.btnOne-group__item {
  float: left;
}
.btnOne-group__item:first-child .btnOne {
  border-radius: 30px 0 0 30px;
}
.btnOne-group__item .btnOne {
  border-radius: 0;
  margin: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btnOne-group__item:last-child .btnOne {
  border-radius: 0 30px 30px 0;
  border-right: 0;
}

.period-button:hover {
 background: rgba(255, 255, 255, 0.3);
 border-color: #ffffff;
}
