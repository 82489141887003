@import "src/assets/scss/all/custom/variables";

.sprite-search-invoice {
  padding: 0 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;

  &__error {
    align-self: flex-start;
    transform: translate(30px, -10px);
    color: $BDA_dark_red-100;
    font-size: 12px;
    backface-visibility: hidden;
    animation: animateError 2s infinite;

  }
}

.no-invoice-details {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.invoice-details {
  margin-top: 20px;

  p {
    margin-bottom: 0.5px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &-item {
      margin-bottom: 25px;
      flex: 0 0 20vw;
    }
  }

 &__channel {
   margin-top: 25px;

   h5 {
     font-weight: 500;
   }

   &-wrapper {
     display: flex;
     flex-wrap: wrap;

     &-sub {
       height: 40px;
       flex: 0 0 10vw;

     }
   }

   &-logo {
     width: 60%;
     transform: translate(20px, -2px);
     z-index: 3;
   }

 }

  &__action-btns {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}


.confirm-payment {
  height: 100%;
  animation-name: animateConfirmPayment;
  animation-duration: 2s;
  animation-timing-function: ease-in;
  overflow: hidden;
  backface-visibility: hidden;

  &__details {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-success {
      color: #16925d;

      h3 {
        color: #16925d;
        font-weight: 500;
      }
    }

    &-failed {
      color: $BDA_dark_red-100;

      h3 {
        color: $BDA_dark_red-100;
        font-weight: 500;
      }
    }

  }
}

#payment-details-form {
  display: none;
}

@keyframes  animateConfirmPayment {
  0% {
     opacity: 0.2;
     transform: translateY(-40px),;

   }

  50% {
    opacity: 0.8;
    transform: translateY(-20px) ;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animateError {
  0% {
    color: rgba(245, 74, 52, 0.1);
  }
  100% {
    color: $BDA_dark_red-100;
  }
}
