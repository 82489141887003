@import "../../assets/scss/all/custom/variables.scss";

#cardLogin {
  border-radius: 1rem;
  max-width: 400px;
}

.amt,
.amt-yellow {
  color: $warning;
}

.paid-amt,
.amt-green {
  color: $BDA_dark_green-100;
}

.outstanding-amt,
.amt-red {
  color: $BDA_dark_red-100;
}

.amt-helper-text {
  color: $warning;
}

.login-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  height: 100vh;
}

.faan-login {
  background-image: linear-gradient(
      rgb(4, 36, 65) 20%,
      rgba(7, 52, 91, 0.705) 90%
    ),
    $faan-bg-img;
}

.ncaa-login {
  background-image: linear-gradient(
      rgb(4, 36, 65) 20%,
      rgba(7, 52, 91, 0.705) 90%
    ),
    $ncaa-bg-img;
}

default-login {
  background-image: linear-gradient(
      rgb(4, 36, 65) 20%,
      rgba(7, 52, 91, 0.705) 90%
    ),
    $default-bg-img;
}
.login-forgot-password {
  text-align: right;
  text-align: right;
  position: absolute;
  left: 65%;
}

.login-row {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerText {
  color: white;
}

.baseText {
  color: white;
  fontsize: 10px;
  fontweight: 300;
}

.loginBtn {
  color: white !important;
  margin-top: 30px;
}

.loginBtn:hover {
  color: #000;
}

.managerLogin {
  background-color: white !important;
  border-color: black !important;
}

.managerLogin:hover {
  color: black !important;
}

input {
  font-size: 15px;
  padding: 10px 10px 10px 5px;
  width: 300px;
  border: none;
  line-height: 1.5;
  color: black;
  border-bottom: 1px solid #757575;

  //&[type="checkbox"] {
  //    width: 20px;
  //}
}

input:focus {
  outline: none;
  cursor: text;
  line-height: 1.5;
}

input:focus {
  outline: none;
  cursor: text;
  line-height: 1.5;
}

::placeholder {
  opacity: 0.5;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.5;
}

.card-control {
  margin: 0 auto;
}

.policy {
  font-size: 10px;
  color: $BDA_light_blue-100;
  // margin-right: 20px;
  cursor: pointer;
  padding-left: 3rem;
}

.custom-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 12px;
  margin-right: 45px;
  cursor: pointer;
}