.inputError{
    color: red;
}

.items-header {
    background-color: #D5DBE6;
    border-radius: 0.5rem 0.5rem 0 0 !important;
}

.items-body {
    background-color: #ECF0F8;
    border-radius: 0 0 0.5rem 0.5rem !important;
}

.pointer-cursor {
    cursor: pointer;
}

.live-view {
    box-shadow: -1px 0px 20px rgba(47, 19, 67, 0.06);
}

.align-button {
    margin-right: 10px;
}

.Add-new-customer .card-body {
    padding: 30px;
}

.similar-customer {
    &__card {
        border-radius: 5px;
    }
    &__title {
       padding: 30px 30px 15px 30px;
        &-main {
          font-weight: bold;color: #000;
        }
        &-subtitle {
          font-size: 12px;
            color: orangered;
        }
     }
    &__table {
        padding: 0 30px;
      thead {
          background-color: #07345B;
          color: #fff;

          th {
              padding-right: 20px;
              padding-left: 20px;
          }
      }
        tbody {
            tr {
                cursor: pointer;
                th {
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }
        }
    }
    &__buttons {
        padding: 20px;
       text-align: right;
    }
}