.invoice-container {
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 24px;
  width: 837px;
  margin: 20px auto;
  height: 490px;

  .invoice-title{
    font-size: 24px;
    font-weight: 500;
    color: #000000;
  }
  .info-heading{
    color: #262626CC;
    font-size: 13px;
  }
  .info-detail{
    color: #262626;
    font-weight: 500;
  }
}

.go-back {
  background: none;
  border: none;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  margin-bottom: 16px;
}

.payment-summary {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;

  h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .payment-det {
    white-space: nowrap;
  }
  
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  gap:10px;
}
.payment-buttons {
  display: flex;
  gap: 12px;

  .partial-payment {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    background: white;
    border-radius: 8px;
    cursor: pointer;
  }

  .full-payment {
    flex: 1;
    padding: 10px;
    background: #d83821;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
}

.partial-payment-modal {
    .modal-content{
      padding: 5px;
      border-radius: 20px;
    }
    .invoice-modal-header {
      font-weight: 500;
      font-size: 1.25rem;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      border: none;

      &.as-button{
        .modal-title{
          cursor: pointer;
        }
      }
      .modal-title{
        color: #0D0D0D;
        font-weight: 600;
        font-size: 24px;
      }
      .popup-info-heading{

      }
      .popup-info-detail{
        font-size: 24px;
        color: #262626;
        font-weight: 500;
      }
    }
  
    .outstanding-amount {
      p {
        font-size: 13px;
        color: #6c757d;
        margin-bottom: 0;
      }
      h2 {
        font-size: 24px;
        color: #262626;
        font-weight: 500;
      }
    }
  
    .enter-amount {
      margin-top: 20px;
      p {
        font-size: 14px;
        margin-bottom: 5px;
      }
      input {
        width: 100%;
        height: 40px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-weight: 500;
        color: #262626;
      }
    }
  
    .terms-of-payment {
      background: #f8f9fa;
      padding: 15px;
      margin-top: 20px;
      border-radius: 8px;
      
      h5 {
        font-size: 16px;
        margin-bottom: 10px;
      }
  
      ul {
        list-style: none;
        padding: 0;
        font-size: 14px;
        li {
          position: relative;
          padding-left: 20px;
          margin-bottom: 5px;
        }
  
        li::before {
          content: "•";
          position: absolute;
          left: 0;
          top: 2px;
          color: #6c757d;
        }
      }
    }

    .main-popup-information{
      display: flex;
      gap: 2;
      align-items: start;

      .popup-info {
        width: 50%;
        p {
          font-size: 13px;
          color: #6c757d;
          margin-bottom: 0;
        }
        h2 {
          font-size: 24px;
          color: #262626;
          font-weight: 500;
        }
      }
    }
  
    .invoice-modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border: none;
    //   justify-content: space-between;
    }
  
    .cancel-btn {
      background: #fff;
      border: 1px solid #ccc;
      color: black;
      border-radius: 12px;
    }
  
    .proceed-btn {
      background: #1F1F1F;
      border: none;
      color: white;
      border-radius: 12px;
    }

    .rrr-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }
      
      .rrr-title {
        align-self: stretch;
        text-align: center;
        color: rgba(37.97, 37.97, 37.97, 0.80);
        font-size: 16px;
        font-family: Roboto, sans-serif;
        font-weight: 500;
      }
      
      .rrr-number {
        align-self: stretch;
        color: #262626;
        font-size: 32px;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        text-align: center;
      }
  }

  .status-label {
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .status-label.paid {
    background-color: rgba(0, 128, 0, 0.1);
    color: green;
  }
  
  .status-label.unpaid {
    background-color: rgba(255, 0, 0, 0.1);
    color: red;
  }