.stract {
  background-color: #f4f4f4;
  padding: 50px;
align-content: center;}

.card-payment {
  width: 100%;
  /* height: 384px; */
  border-radius: 20px;
  background: #fff;
}

.invoice-auth {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  
}

.invoice-details {
  color: #929292;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.center-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .payment-button {
    width: 500px;
    padding: 20px;
    background-color: #007948;
    border-radius: 10px;
    color: #fff;
    border: none; /* Remove default button border if needed */
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
  }

.center-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .payment-logo {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  

.payment-status {
    text-align: center;
  }
  
  .center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .payment-secure {
    color: #bababa;
    font-size: 10px;
    font-weight: 400;
    margin-top: 20px;
  }
  
  .agency-logo{
    justify-content: center;
    margin-top: 10px;
}

.loading-scr {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #bababa;
}

.loading-log {
  color: #007948;
  font-size: 20px;
  /* font-family: ; */
  
}

.load-bar {
  width: 150px;
  height: 2px;
  position: relative;
  overflow: hidden;
  background: #cfcfcf;
  margin-top: 22px;
}

.load-bar::before {
  content: '';
  width: 75px;
  height: 2px;
  position: absolute;
  left: -34px;
  background: #007948;
  animation: loadBar 1.5s infinite ease;
}

.loading-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*-- load bar animation CSS--*/
@keyframes loadBar {
  50% {
    left: 100px;
  }
}
.custom-stract {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    display: flex;
  }
  
  .custom-column {
    text-align: center;
     color: #929292;
    font-size: 13px;
    font-weight: 500; 
    white-space: nowrap;
    /* word-wrap: break-word; */
  }

  .down-column{
        text-align: center;
         color: black;
        font-size: 10px;
        font-weight: 500; 
        word-wrap: break-word;
  }
/*   
 .table-column{
    background-color: #FAFAFA;
    border-radius: 10px;
    width: 28px;
    
 } */
  
