.add-new {
  background-color: #fff;
  border-top: 1px double #eee;
  color: rgb(41,146,208);
  padding: 10px 10px 25px 10px;
  text-align: center;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  height: 27px;
  cursor: pointer;
}

.react-select__indicator-separator {
  margin-bottom: 16px !important;
  margin-top: 4px !important;
  border: none;
  //background-color: transparent !important;
}
.react-select__control {
 height: 25px;
  border-radius: 4px !important;
}

.react-select__multi-value {
  background-color: #ebf5ff !important;
  border-radius: 2px;
  border: 1px solid rgba(0,126,255,.24);
  color: #007eff;
  display: inline-block;
  font-size: .9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
  margin-bottom: 10px !important;
}

.react-select__multi-value__label {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid rgba(0,126,255,.24);
  padding: 1px 5px 3px;
  //float: left;
  color: #007eff !important;
}