div .invoiceButtons {
    /* margin-left: 20px; */
    color: black;
    border: 1px solid #ccc;
    background: white;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 5px;
    text-align: left;
    font-size: initial;
    margin-bottom: 0.5rem;
    border-radius: 0.4rem;
  }
  
  .spanClose:hover {
    color: black !important;
  }
  /* The Modal (background) */
  .activityModal {
    display: flex;
    position: fixed;
    padding-top: 70px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    /*overflow: hidden;*/
    /*background-color: rgb(0,0,0);*/
    background: rgba(3, 41, 61, 0.8);
    /*z-index: 90;*/
    /*position: fixed;*/
  }
  
  /* Modal Content */
  .activityModal-content {
    position: relative;
    background-color: transparent;
    margin: auto;
    padding: 0;
    height: 100vh;
    /*top: 50%;*/
    /* left: 5%; */
    /* border: 1px solid #888; */
    /*width: 70%;*/
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19); */
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  
  /* The Close Button */
  span .close {
    color: white !important;
    float: right;
    font-size: 28px;
    font-weight: bold;
    opacity: 1;
  }
  
  span .close:hover,
  span.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-header {
    /* padding: 2px 16px; */
    background-color: transparent;
    color: white;
    display: block !important;
  }
  