/* The container */
label.label-container {
  display: inline;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}


/* Hide the browser's default radio button */
label.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 2px solid #808080;
}

/* On mouse-over, add a grey background color */

/* When the radio button is checked, add a blue background */
label.label-container input:checked ~ .checkmark {
  border: 2px solid green;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
label.label-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
label.label-container .checkmark:after {
  top: 1.2px;
  left: 1.2px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background:  linear-gradient(0deg, #4DBD98, #4DBD98), linear-gradient(0deg, #4DBD98, #4DBD98), linear-gradient(0deg, #4DBD98, #4DBD98), #4DBD98;
  border-color: #000;
}