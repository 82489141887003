label {
    /* display: block; */
    color: #7d7d7d;
  }
  
  .floatBlock {
    font-weight: bold;
  }
  
  .labelish {
      color:#7d7d7d;
      margin: 0;
  }
  
  .labelDesign{
    padding-left: 46px;
    margin-right: 13rem;
  }

  .paymentOptions {
      display: flex;
      /* flex-direction: row; */
      /* justify-content: space-between; */
      /* align-items: center; */
  }
  
  
    .fileDesign{
    margin:40px;
    gap: 16px;
    padding: 24px;
    border-style: dashed;
    /* border:#7F91A8; */
    /* border: 0.5px solid black; */
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }