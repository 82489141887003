.backgrd{
    background-image: linear-gradient(  rgb(4, 36, 65) 20%, rgba(50, 41, 75, 0.9) 90%), url("../../assets/images/BACKGROUND.jpg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.colStructure{
    top: 20%;
     padding: 0
}
.headerText {
    color: white;
}

.baseText {
    color: white;
}

.activate-logo {
    color: #ffffff;
    width: 150px;
}

.logo-wrapper {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-53%, -50%);
}

.activate-message {
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 53%;
    left: 47%;
    transform: translate(-47%, -50%);


    span {
        font-size: 18px;
        font-weight: bold;
    }

    p:nth-child(2) {
        display: flex;
        justify-content: center;
    }
}

.activateBtn {
    color: white !important;
    margin-top: 100%;
    top: 50%;
    font-weight: bolder;
    position: absolute;
    left: 30%;
}

.vertical-center {
    min-height: 100vh; /* These two lines are counted as one :-)  */

    display: flex;
    align-items: center;
}

.loginBtn:hover {
    color: #000;
}

::placeholder {
    opacity: 0.5;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.5;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    opacity: 0.5;
}
