@import "./../variables";

.green--100 {
  color: $BDA_dark_green-100
}

.green--60 {
  color: $BDA_dark_green-60;
}

.green--10 {
  color: $BDA_dark_green-10;
}

.light-blue--100 {
  color: $BDA_light_blue-100;
}

.light-blue--30 {
  color: $BDA_light_blue-30;
}

.light-blue--10 {
  color: $BDA_light_blue-10;
}

.red--100 {
  color: $BDA_dark_red-100;
}

.red--30 {
  color: $BDA_dark_red-30;
}

.red--10 {
  color: $BDA_dark_red-10;
}

.border-red {
  border: 1px solid $BDA_dark_red-100;
}

.border-green {
  border: 1px solid $BDA_dark_green-100;
}


/**Background colours**/
.bg-green--100 {
  background-color: $BDA_dark_green-100;
}

.bg-red--100 {
  background-color: $BDA_dark_red-100;
}