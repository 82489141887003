
.table-container {
    border: 1px solid #dee2e6;
}
input[type="checkbox"] {
    visibility: visible;
}

.permissions-table th, .permissions-table td {
    padding: 0.8rem 2rem;
    vertical-align: top;
    text-align: left;
}

.permissions-table thead tr {
    visibility: hidden;
}

.permissions-table {
    margin-bottom: 0
}

.permissions-container {
    margin-bottom: 50px;
}

.permissions-container h2 span{
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    background: #28BAF0;
    width: 44px;
    padding: 5px 14px;
    border-radius: 3px;
}

.permissions-title {
    margin-bottom: 15px;
}

.search-container {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 50px;
}

.spacing-row{
    margin-top: 50px;
}