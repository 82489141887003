.invoice-items {

  &__header {
    background-color: #D5DBE6;
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }

  &__body {
    background-color: #ECF0F8;
    border-radius: 0 0 0.5rem 0.5rem !important;
  }
}