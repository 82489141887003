// App
* {
  margin: 0;
  padding: 0;
  // overflow: hidden;
}

.icon-margin {
  margin-right: 5px;
}

.search-field {
  top: -12px;
}

.table th,
.table td {
  padding: 0.7rem;
}

.void {
  padding: 10px 15px;
  font-size: 16px;
}

.big-action-btn {
  padding: 6px 15px;
  font-size: 16px;
}

.create-action-btn {
  padding: 9px 15px;
  font-size: 16px;
}

.table-margin {
  margin: -50px;
}
.small-action-btn {
  padding: 4px 30px;
  font-size: 14px;
  //color: #fff;
}
.table-main {
  font-size: 14px;
  cursor: pointer;
  color: $BDA_table_color;
}

.table-head {
  color: #ffffff;
  background-color: $BDA_dark_lilac-30;
}

.table-head-blue {
  color: #ffffff;
  background-color: $BDA_dark_blue-100;
}

.th-head {
  color: #ffffff;
  background-color: #29baf1;
}

.tracker-head {
  color: #ffffff;
  background-color: #1d5e96;
}
.audit-head {
  color: #ffffff;
  background-color: #6c77c9;
}

.custom-card-title {
  font-size: 18px;
  padding: 5px;
  font-weight: 500;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.notification-alert {
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}

.settings-spacing {
  padding-top: 8%;
  margin: 0 -27px 20px;
}

.main-heading {
  font-size: $main-heading;
  font-weight: $main-heading-weight;
}

.sub-heading {
  font-size: $sub-heading;
  font-weight: $sub-heading-weight;
}

.table-heading {
  font-size: $table-heading;
  font-weight: $table-heading-weight;
}
//buttons

.big-button {
  background-color: rgb(64, 217, 153);
  color: #ffffff;
  width: 130px;
  border: none;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: rgba(64, 217, 153, 0.6);
  }
}

.bigger-button {
  background-color: rgb(64, 217, 153);
  color: #ffffff;
  width: 190px;
  border: none;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: rgba(64, 217, 153, 0.6);
  }
}

.small-button {
  background-color: rgb(64, 217, 153);
  color: #ffffff;
  width: 80px;
  border: none;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: rgba(64, 217, 153, 0.6);
  }
}
.cancel-button {
  background-color: rgba(7, 52, 91, 0.1);
  color: #ffffff;
  width: 130px;
  border: none;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: rgba(7, 52, 91, 0.3);
  }
}

.small-cancel-button {
  background-color: rgba(7, 52, 91, 0.1);
  color: #ffffff;
  width: 80px;
  border: none;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: rgba(7, 52, 91, 0.3);
  }
}

.sec-negative-button {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.7);
  width: 130px;
  border: 0.1px solid rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: rgba(7, 52, 91, 0.3);
  }
}

.small-negative-button {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.7);
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: rgba(7, 52, 91, 0.3);
  }
}

.big-negative-button {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.7);
  width: 180px;
  border: 0.1px solid rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: rgba(7, 52, 91, 0.3);
  }
}

.bigger-negative-button {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.7);
  width: 210px;
  border: 0.1px solid rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: rgba(7, 52, 91, 0.3);
  }
}

.cancel-btn {
  border-color: #888888;
  color: black;

  &:hover {
    background: rgba(7, 52, 91, 0.3);
    border-color: #e7e7e7;
  }
}

.right-aligned-action-buttons {
  display: flex;
  justify-content: flex-end;
}
//

.welcome-screen {
  white-space: nowrap;
  overflow: hidden;
}

.request-card {
  // font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

//tooltip
.tooltip-inner {
  max-width: 200px;
  padding: 0.05rem 0.15rem;
  color: #fff;
  text-align: center;
  background-color: #444;
  border-radius: 3px;
}

.tooltip {
  pointer-events: none;
}
/* card */
.bda-card {
  border-radius: 10px;

  &__body {
    padding: 20px;
  }

  &__title {
    font-size: 18px;
    padding: 5px;
    font-weight: bold;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
  }
}

@-webkit-keyframes notification-alert {
  100% {
    right: 0;
  }
}

@keyframes notification-alert {
  100% {
    right: 0;
  }
}
/*============================================================== 
 For all pages 
 ============================================================== */

#main-wrapper {
  width: 100%;
  overflow: hidden;
  .show-right-sidebar {
    .left-sidebarRight {
      display: block;
    }
  }
}

.page-wrapper {
  background: $body-bg;
  position: relative;
  transition: 0.2s ease-in;
  display: none;
}

.page-wrapper > .page-content {
  padding: 30px;
  min-height: calc(100vh - 116px);
  margin-top: -107px;
}

.page-wrapper > .no-sidebar-page-content {
  padding: 30px 150px;
  min-height: calc(100vh - 105px);
  margin-top: -107px;
}

.page-breadcrumb {
  padding: 30px 30px 0px;
}

/*******************
 Footer
*******************/

.footer {
  padding: 15px 20px;
}

/*******************
 Stickey inner-left-part
*******************/

.left-part {
  width: 200px;
  position: absolute;
  border-right: 1px solid $border-color;
  &.chat-list {
    width: 260px;
  }
  .email-filters,
  .contact-filters,
  .chat-users {
    height: calc(100vh - 242px);
    overflow: auto;
  }
  .chat-users {
    height: calc(100vh - 274px);
  }
}

.right-part {
  width: calc(100% - 200px);
  height: calc(100vh - 175px);
  margin-left: 200px;
  &.chat-list {
    width: calc(100% - 260px);
    margin-left: 260px;
  }
}

.right-left-part {
  width: 300px;
  position: absolute;
  border-right: 1px solid $border-color;
  .email-list,
  .contact-list {
    height: calc(100vh - 300px);
    overflow: auto;
  }
  .contact-list {
    height: calc(100vh - 258px);
  }
}

.right-right-part {
  width: calc(100% - 300px);
  height: calc(100vh - 175px);
  overflow: auto;
  margin-left: 300px;
}

.reverse-mode {
  .left-part {
    right: 0;
    border-left: 1px solid $border-color;
  }

  .show-left-part {
    right: auto;
    left: -41px;
  }

  .right-part {
    margin-left: 0px;
    margin-right: $left-part-width;
  }
}

/*******************
 Vertical layout
*******************/

#main-wrapper[data-layout="vertical"] {
  /*Vertical*/
  .topbar .top-navbar .navbar-header {
    //width: $sidebar-width-full;
    transition: 0.2s ease-in;
  }

  /*sidebar type*/
  /* &[data-sidebartype='full'] {
    .page-wrapper {
      margin-left: $sidebar-width-full;
    }
  } */
  &[data-sidebartype="iconbar"] {
    .page-wrapper {
      margin-left: $sidebar-width-iconbar;
    }

    .left-sidebar,
    .topbar .top-navbar .navbar-header,
    .sidebar-nav ul .sidebar-item {
      width: $sidebar-width-iconbar;
    }

    .sidebar-nav {
      ul .sidebar-item {
        .sidebar-link {
          display: block;
          text-align: center;

          i {
            display: block;
            width: auto;
          }
        }
      }
    }
  }

  &[data-sidebartype="overlay"] {
    .topbar .top-navbar .navbar-header {
      width: $sidebar-width-full;
    }

    .left-sidebar {
      left: -$sidebar-width-full;
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }
  }

  /*Sidebar position*/
  &[data-sidebar-position="fixed"] {
    .topbar .top-navbar .navbar-header {
      position: fixed;
      z-index: 10;
    }

    .left-sidebar {
      position: fixed;
    }
  }

  /*Header position*/
  &[data-header-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
    }

    .page-wrapper {
      padding-top: $topbar-height;
    }
  }

  /*Boxed Layout*/
  &[data-boxed-layout="boxed"] {
    max-width: $boxed-width;
    margin: 0 auto;
    position: relative;
    box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
  }

  &[data-boxed-layout="boxed"][data-header-position="fixed"] {
    .topbar {
      max-width: $boxed-width;
    }
  }
}

/*******************
 Vertical responsive layout
*******************/

@include media-breakpoint-up(md) {
  #main-wrapper[data-layout="vertical"] {
    /*Vertical*/
    &[data-sidebartype="full"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-full;
      }
    }

    /*Sidebar position*/
    &[data-sidebar-position="fixed"][data-sidebartype="full"],
    &[data-sidebar-position="fixed"][data-sidebartype="overlay"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-full;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="iconbar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-iconbar;
      }
    }

    /*Vertical with mini-sidebar*/
    &[data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-mini;

        .logo-text {
          display: none;
        }

        &.expand-logo {
          width: $sidebar-width-full;

          .logo-text {
            display: block;
          }
        }
      }
    }

    .show-right-sidebar {
      .left-sidebarRight {
        display: block;
      }
    }

    /*Fixed sidebar with minisidebar*/
    //&[data-sidebartyper='mini-sidebar'] {
    //	.left-sidebarRight {
    //		display: block;
    //	}
    //}
    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    &[data-sidebartype="mini-sidebar"] {
      .page-wrapper {
        margin-left: $sidebar-width-mini;
      }

      .sidebar-nav {
        .hide-menu,
        .has-arrow:after {
          display: none;
        }

        .nav-small-cap {
          justify-content: center;
        }
      }
      .left-sidebarRight {
        display: none;
      }
      .left-sidebar {
        width: $sidebar-width-mini;
        overflow: hidden;

        &:hover {
          width: $sidebar-width-full;

          .sidebar-nav {
            .hide-menu,
            .has-arrow:after {
              display: block;
            }

            .nav-small-cap {
              justify-content: flex-start;
            }
          }

          .first-level {
            .sidebar-item .sidebar-link i {
              visibility: hidden;
            }
          }

          .second-level .sidebar-item {
            padding: 0 0 0 20px;
          }
        }

        .first-level {
          .sidebar-item .sidebar-link i {
            visibility: visible;
          }
        }

        .second-level .sidebar-item {
          padding: 0 0 0 0px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #main-wrapper {
    &[data-sidebartype="mini-sidebar"] {
      .left-sidebar {
        left: -$sidebar-width-full;
      }
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }

    &[data-layout="vertical"][data-sidebar-position="fixed"] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }

    /*Header position*/
    &[data-layout="vertical"][data-header-position="fixed"][data-sidebar-position="fixed"] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }
  }

  /* .left-part {
    left: -$left-part-width;
    background: $body-bg;
    z-index: 1;
    top:$topbar-height;
    height: calc(100vh - 66px);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
    position: fixed;
    transition: 0.1s ease-in;
    &.show-panel {
      left: 0px; 
    }
  } */
  .reverse-mode .left-part {
    right: -$left-part-width;
    left: auto;

    &.show-panel {
      right: 0px;
    }
  }

  .right-part {
    width: 100%;
    margin-left: 0px;
    //height: 500px;
  }
}

@media (min-width: 1024px) {
  #main-wrapper[data-layout="vertical"][data-sidebartype="full"] .page-wrapper {
    margin-left: $sidebar-width-full;

    .show-right-sidebar {
      .left-sidebarRight {
        display: block;
      }
    }
  }
}

@media (min-width: 1024px) {
  .action-card {
    border-radius: 15px;
    border: 1px solid #eee;
    // overflow: hidden;
    white-space: nowrap;
    &:hover {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .action-card {
    border-radius: 15px;
    border: 1px solid #eee;
    overflow: hidden;
    white-space: nowrap;
    font-size: 10px;
    &:hover {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
}

@media (max-width: 600px) {
  .action-card {
    border-radius: 15px;
    border: 1px solid #eee;
    // overflow: hidden;
    // white-space: nowrap;
    font-size: 10px;
    &:hover {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
}

@media (max-width: 600px) {
  .image-card {
    width: 50px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .image-card {
    width: 70px;
  }
}
