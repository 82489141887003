.ghost-button {
  color: #3A434B;
  background-color: transparent;
  margin: 8px 0px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.next-step-buttons {
  display: flex;
  justify-content: flex-end;
  flex: 0 1 300px;
  margin-top: 30px;

  button {
    margin-right: 10px;
  }
 }

.br-4 {
  border-radius: 4px;
}